import React from 'react';
import './Header.scss';
import sections from './sections.json';
import { Badge, Button, Image, Stack } from 'react-bootstrap';

const Header = () => {
  return (
    <section id='header' className='info-panel text-light text-center pt-2 pb-3 px-3 m-4 border border-3 border-secondary border-opacity-25 bg-secondary bg-opacity-10 position-relative'>
      <h1 className='fs-1 fs-sm-3'>George Goodey</h1>
      <h5 className='text-light text-opacity-50'>Student at the University of Manchester</h5>
      <Stack id="page-section" direction='horizontal' gap={3} className='row justify-content-center d-none d-lg-block'>
        {
          sections.map((section, index) => 
            <Button key={index} className={`nav-button mx-2 rounded-0 border border-3 border-${section.color}Accent bg-${section.color}Accent bg-opacity-10 border-opacity-25 px-3 pt-3 mt-3 col-auto`}>
              <a href={`/${section.url}`} className='link-underline nav-link link-underline-opacity-0 link-secondary'>
                <Stack>
                  <svg style={{height:'4vh'}} xmlns="http://www.w3.org/2000/svg" viewBox={section.view} className='text-center'><path className='draw-icon' d={section.icon}/></svg>
                  <p className='fs-6 text-light text-opacity-75 mt-2 mb-1'>{section.name}</p>
                </Stack>
              </a>
            </Button>
          )
        }
        </Stack>
        <Stack id="page-section" direction='vertical' gap={3} className='row justify-content-center d-lg-none d-block'>
        {
          sections.map((section, index) => 
            <Button key={index} className='nav-button mx-2 rounded-0 border border-3 border-primary bg-primary bg-opacity-10 border-opacity-25 px-2 mt-3 px-5 mx-5 py-0 col-9'>
              <a href={`/${section.url}`} className='link-underline link-underline-opacity-0 link-secondary py-0 px-1'>
                <Stack direction='horizontal' className='py-0'>
                  <svg style={{height:'4vh'}} xmlns="http://www.w3.org/2000/svg" viewBox={section.view} className='text-center'><path className='draw-icon' d={section.icon}/></svg>
                  <p className='fs-6 fw-bolder text-light text-opacity-75 px-4 py-0 mt-1 mb-1'>{section.name}</p>
                </Stack>
              </a>
            </Button>
          )
        }
        </Stack>
    </section>
  );
};

export default Header;
