// components/AboutMe.js

import React from 'react';

const AboutMe = () => {
  return (
    <section id="about-me" className='info-panel text-light bg-blue bg-opacity-10 border-opacity-25 border border-3 border-blue pt-2 pb-0 px-3 m-4'>
        <h1>About Me</h1>
        <h5>
          I'm currently a fourth year student at the University of Manchester completing a Masters in Artificial Intelligence. I've just finished the BSc portion of my Degree and achieved a first class qualification. Over the summmer I completed an internship at BTIG in London, learning a variety of skills and immersing myself into the work culture.
        </h5>
    </section>
  );
};

export default AboutMe;
