import React, { useState } from 'react';
import projects from './projects.json'
import './Projects.scss'
import Iframe from 'react-iframe'
import { Button, Stack } from 'react-bootstrap';

const Projects = () => {
  const [openProject, setOpenProject] = useState();

  return (
    <section id="projects" className='info-panel text-light bg-blue bg-opacity-10 border-opacity-25 border border-3 border-secondary pt-2 pb-0 px-3 m-4'>
      <h2>Projects</h2>
      <ul>
        {projects.map((project, index) => (
          <li key={index} className='info-panel border border-3 ps-4 pe-3 pt-3 my-3 border-blueAccent bg-primary bg-opacity-10 border-opacity-25' onClick={()=>setOpenProject(project)}>
            <h3>{project.title}</h3>
            <p className='fs-5 text-light text-opacity-75'>{project.summary}</p>
            {project.site === "yes" &&
              <Iframe url={project.url}
                width="640px"
                height="320px"
                id=""
                className=""
                display="block"
                position="relative"/>
            }
          </li>
        ))}
      </ul>
      {projects.map((project, index) => (
        <div key={index} style={{position:"fixed", width:"70%",height:"50%"}} className={`overflow-auto border top-25 start-15 border-3 ps-4 pe-3 pt-3 border-primary bg-secondary rounded ${(project == openProject) ? "d-block" : "d-none"}`}>
          <Stack direction='horizontal' className='justify-content-between'>
            <h2 className=''>{project.title}</h2>
            <Button className='btn-close border border-3' onClick={()=>setOpenProject(null)}></Button>
          </Stack>
          <p className='fs-6 text-light text-opacity-75 mb-1'>{project.date}</p>
          <h5>{project.summary}</h5>
          <p>{project.description}</p>
          <div className='row justify-content-center'>
          <h3 className='col-auto'>Skills</h3>
          <div className='w-100'></div>
          {project.skills.map((skill, index) => (
            <div key={index} className="col-auto text-light text-opacity-75 fs-5 mx-2 my-2 border border-2 border-primary bg-black bg-opacity-10 py-2 px-3 rounded text-center">
              {skill}
            </div>
          ))}
          </div>
        </div>
      ))}
      
    </section>
  );
};

export default Projects;
