import './Home.scss';
import React from 'react';
import Header from '../Header/Header';
import AboutMe from '../AboutMe';
import Education from '../Educations';
import Projects from '../Projects/Projects';
import Contact from '../Contact';
import Login from '../Login/Login';
import Experience from '../Experience/Experience';

function Home() {
  return (
    <div className="Home">
      <Header />
      <Login />
      <AboutMe />
      <Experience />
      <Projects />
      <Education />
      <Contact />
    </div>
  );
}

export default Home;