import React, { useState } from 'react';
import educations from './educations.json'
import { Button, Stack } from 'react-bootstrap';

const Educations = () => {
  const [openEducation, setOpenEducation] = useState();

  return (
    <section id="education" className='info-panel text-light bg-blue bg-opacity-10 border-opacity-25 border border-3 border-blue pt-2 pb-0 px-3 m-4'>
      <div></div>
      <h2>Education</h2>
      <ul>
        {educations.map((education, index) => (
          <li key={index} className='info-panel border border-3 ps-4 pe-3 pt-3 my-3 border-blueAccent bg-blueAccent bg-opacity-10 border-opacity-25' onClick={()=>setOpenEducation(education)}>
            <h3>{education.title}</h3>
            <p className='fs-5 text-light text-opacity-75'>{education.summary}</p>
          </li>
        ))}
      </ul>
      {educations.map((education, index) => (
        <div key={index} style={{position:"fixed", width:"70%",height:"50%"}} className={`overflow-auto border top-25 start-15 border-3 ps-4 pe-3 pt-3 border-primary bg-secondary rounded ${(education == openEducation) ? "d-block" : "d-none"}`}>
          <Stack direction='horizontal' className='justify-content-between'>
            <h2 className=''>{education.title}</h2>
            <Button className='btn-close border border-3' onClick={()=>setOpenEducation(null)}></Button>
          </Stack>
          <p className='fs-6 text-light text-opacity-75 mb-1' on={()=>setOpenEducation(null)}>{education.grades}</p>
          <h5>{education.summary}</h5>
          <div className='row justify-content-center'>
          <h3 className='col-auto'>Classes</h3>
          <div className='w-100'></div>
          {education.classes.map((module, index) => (
            <div key={index} className="col-auto text-light text-opacity-75 fs-5 mx-2 my-2 border border-2 border-primary bg-black bg-opacity-10 py-2 px-3 rounded text-center">
              <p className='mb-0'>{module.name} ({module.grade})</p>
            </div>
          ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Educations;